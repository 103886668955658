import { ref, watch } from '@vue/composition-api'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useKanban() {
  // Use toast
  const toast = useToast()

  const clientListAssigned = ref(null)
  const lanes = ref([])
  const items = ref([])

  const stages = []
  const blocks = []

  const transformTasks = tasks => {
    // Initialize an array to hold the transformed tasks
    const transformedTasks = []

    // Create a set to store unique taskStatus values
    const uniqueStatus = new Set()

    // Loop through each task
    tasks.forEach((task, index) => {
      // Create a new object with the desired format
      if (task.title) {
        const transformedTask = {
          uid: task.id, // Assign a unique id
          id: index + 1, // Assign a unique id
          status: task.status,
          taskStatus: task.status,
          title: task.title || '', // Default to empty string if title is not provided
          description: task.description || '', // Default to empty string if description is not provided
          timelineInit: task.timelineInit,
          timelineEnd: task.timelineEnd,
          priority: task.priority,
        }

        // Push the transformed task into the transformedTasks array
        transformedTasks.push(transformedTask)
      }

      // Add the taskStatus to the uniqueStatus set
      uniqueStatus.add(task.status)
    })

    // Initialize an array to hold the lanes
    const newlanes = []

    // Convert the uniqueStatus set into an array and create lanes objects
    Array.from(uniqueStatus).forEach(status => {
      newlanes.push(status)
    })
    // Return an object containing the transformedTasks array and the lanes array
    return { transformedTasks, newlanes }
  }

  const fetchKanban = idItem => {
    items.value = JSON.parse(JSON.stringify(blocks))
    lanes.value = JSON.parse(JSON.stringify(stages))
    store
      .dispatch('app-kanban/fetchKanban', { id: idItem })
      .then(response => {
        const { statuses } = response.data
        const { transformedTasks } = transformTasks(response.data.tasks)
        items.value = JSON.parse(JSON.stringify(transformedTasks))
        lanes.value = JSON.parse(JSON.stringify(statuses))
        // items.value = JSON.parse(JSON.stringify(blocks))
        // lanes.value = JSON.parse(JSON.stringify(stages))
      })
      .catch(err => {
        console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteBlock = ids => {
    store
      .dispatch('app-kanban/deleteTask', ids)
      .then(() => {
        fetchKanban(ids.id)
      })
      .catch(err => {
        console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error deleting task',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const updateBlock = (ids, task) => {
    store
      .dispatch('app-kanban/updateTask', { ids, task })
      .then(() => {
        fetchKanban(ids.id)
      })
      .catch(err => {
        console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating task',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  watch([clientListAssigned], () => {
    fetchKanban(clientListAssigned.value)
  })

  return {
    clientListAssigned,
    fetchKanban,
    updateBlock,
    deleteBlock,
    lanes,
    items,
  }
}
