import kanbanService from '@core/services/kanban/kanbanService'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchKanban(ctx, { id }) {
      return new Promise((resolve, reject) => {
        kanbanService.getKanban(id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllKanban() {
      return new Promise((resolve, reject) => {
        kanbanService.getAllKanban()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTaskKanban(ctx, task) {
      return new Promise((resolve, reject) => {
        kanbanService.addTaskKanban(task)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTask(ctx, { ids, task }) {
      return new Promise((resolve, reject) => {
        kanbanService.updateTask(ids, task)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTask(ctx, ids) {
      return new Promise((resolve, reject) => {
        kanbanService.deleteTask(ids)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // fetchUser(ctx, { id }) {
    //   return new Promise((resolve, reject) => {
    //     userService
    //       .getUser(id)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
