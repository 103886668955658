<template>
  <!-- Table Container Card -->
  <div
    class="card"
    style="height: 77vh;"
  >
    <add-column-modal
      :lanes="lanes"
      :selected-kanban="selectedKanban"
      :refresh-handler="fetchKanban"
      :selected-task="selectedTask"
      :update-block="updateBlock"
    />

    <b-card
      no-body
      class="invoice-preview-card"
    >
      <b-row
        class="d-flex my-3 mx-2"
      >
        <b-col
          cols="12"
          md="2"
        >
          <b-list-group>
            <vue-perfect-scrollbar>
              <b-list-group-item
                v-for="client in clientList"
                v-show="client.client.user !== null || client.agent.user !==null"
                :key="client.id"
                :active="selectedKanban === client.id"
                class="flex-column align-items-start"
                @click="selectKanban(client)"
              >
                <div class="d-flex w-100 justify-content-between my-1">
                  <h5
                    class="mb-1"
                    :class="selectedKanban === client.id ? 'text-white' : 'text-primary'"
                  >
                    {{ showName(client.client.user, client.agent.user) }}
                  </h5>
                </div>
              </b-list-group-item>
            </vue-perfect-scrollbar>
          </b-list-group>
        </b-col>
        <b-col
          cols="12"
          md="10"
        >
          <kanban-board
            :stages="lanes"
            :blocks="items"
            class="flex-column"
            @update-block="updateTask"
          >
            <div
              v-for="stage in lanes"
              :slot="stage"
              :key="stage"
              class="card-header"
            >
              <b-card-title>{{ stage }}</b-card-title>
              <b-card-text class="font-small-2 mb-0">
                <b-button
                  v-if="stage === 'To Do'"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  v-b-modal.add-column-modal
                  size="sm"
                  class=""
                  variant="flat-primary"
                >
                  <feather-icon
                    icon="PlusCircleIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Add Task</span>
                </b-button>
              </b-card-text>

            </div>
            <div
              v-for="block in items"
              :slot="block.id"
              :key="block.id"
            >
              <div class="card-header px-50">
                <b-card-title>{{ block.title }}</b-card-title>
                <b-card-text class="font-small-2 mr-25 mb-0">
                  <b-dropdown
                    id="dropdown-grouped"
                    v-model="block.priority"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="flat-primary"
                    dropright
                    class="dropdown-icon-wrapper"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreHorizontalIcon"
                        size="16"
                        class="align-middle"
                      />
                    </template>
                    <b-dropdown-item
                      v-b-modal.add-column-modal
                      variant="primary"
                      @click="selectTask(block)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="18"
                      />
                      <span>Edit</span>

                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="primary"
                      @click="deleteTask(block)"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                        size="18"
                      />
                      <span>Delete</span>

                    </b-dropdown-item>
                  </b-dropdown>
                </b-card-text>
              </div>
              <div class="card-body">
                <b-row>
                  <p>
                    {{ block.description }}
                  </p>
                </b-row>
              </div>
              <div class="card-footer border-0">
                <small class="text-muted">
                  {{ prettierFormatUnixTimestamp(block.timelineEnd) }}
                </small>
                <b-dropdown
                  id="dropdown-grouped"
                  v-model="block.priority"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  :variant="`flat-${variantPriority(block.priority)}`"
                  right
                  class="dropdown-icon-wrapper"
                >
                  <template #button-content>
                    <feather-icon
                      icon="FlagIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>
                  <b-dropdown-item
                    value="High"
                    variant="danger"
                    @click="updateTaskPriority(block, 'High')"
                  >
                    <feather-icon
                      icon="FlagIcon"
                      size="18"
                    />
                    <span>High</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    variant="warning"
                    value="Medium"
                    @click="updateTaskPriority(block, 'Medium')"
                  >
                    <feather-icon
                      icon="FlagIcon"
                      size="18"
                    />
                    <span>Medium</span>

                  </b-dropdown-item>
                  <b-dropdown-item
                    variant="success"
                    value="Low"
                    @click="updateTaskPriority(block, 'Low')"
                  >
                    <feather-icon
                      icon="FlagIcon"
                      size="18"
                    />
                    <span>Low</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </kanban-board>
        </b-col>
      </b-row>
    </b-card>
  </div>

</template>

<script>
import {
  VBToggle, BRow, BCard, BCol, BListGroup, BListGroupItem, BDropdown, BDropdownItem, BButton, BCardText, BCardTitle,
} from 'bootstrap-vue'
import { avatarText, unixTimestampToDate, prettierFormatUnixTimestamp } from '@core/utils/filter'
import { ref, onUnmounted } from '@vue/composition-api'
import { $themeConfig } from '@themeConfig'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Swal from 'sweetalert2'
import Ripple from 'vue-ripple-directive'
import kanbanStoreModule from './kanbanStoreModule'
import useKanban from './useKanban'
import store from '@/store'
import addColumnModal from './modal/addColumnModal.vue'

// import SidebarAddNewTicket from './sidebar/SidebarAddNewTicket.vue'

export default {
  components: {
    // BCardText,
    BRow,
    BCard,
    BCardText,
    BCol,
    BButton,
    // BFormGroup,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    addColumnModal,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      avatarText,
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-kanban'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, kanbanStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const clientList = ref([])
    const selectedKanban = ref('')
    const selectedTask = ref({})

    // App Name
    const { appLogoImage } = $themeConfig.app

    store.dispatch('app-kanban/fetchAllKanban')
      .then(response => {
        clientList.value = response.data
      })
      .catch(error => {
        console.log(error.message)
      })

    const {
      fetchKanban,
      clientListAssigned,
      kanban,
      lanes,
      items,
      updateBlock,
      deleteBlock,
    } = useKanban()

    const selectKanban = item => {
      selectedKanban.value = item.id
      fetchKanban(item.id)
    }

    const updateTaskPriority = (item, priority) => {
      const ids = {
        idTask: item.uid,
        id: selectedKanban.value,
      }
      // eslint-disable-next-line no-param-reassign
      item.priority = priority
      updateBlock(ids, item)
    }

    const updateTask = (id, status) => {
      const task = {
        title: items.value[id - 1].title,
        description: items.value[id - 1].description,
        status,
        timelineInit: items.value[id - 1].timelineInit,
        timelineEnd: items.value[id - 1].timelineEnd,
        priority: items.value[id - 1].priority,
      }
      const ids = {
        idTask: items.value[id - 1].uid,
        id: selectedKanban.value,
      }
      updateBlock(ids, task)
    }

    const selectTask = task => {
      selectedTask.value = ref(task)
    }

    const deleteTask = task => {
      const ids = {
        idTask: task.uid,
        id: selectedKanban.value,
      }
      Swal.fire({
        title: 'Attention',
        text: 'Are you sure you want to delete this task',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
      }).then(result => {
        if (result.isConfirmed) {
          deleteBlock(ids)
        }
      })
    }

    return {
      appLogoImage,
      selectKanban,
      selectedKanban,
      updateBlock,
      updateTask,
      selectTask,
      selectedTask,
      deleteTask,
      deleteBlock,

      unixTimestampToDate,
      prettierFormatUnixTimestamp,

      lanes,
      items,
      fetchKanban,
      kanban,
      clientList,
      clientListAssigned,
      updateTaskPriority,
    }
  },
  methods: {
    variantPriority(priority) {
      switch (priority) {
        case 'High':
          return 'danger'
        case 'Medium':
          return 'warning'
        case 'Low':
          return 'success'
        default:
          return 'primary'
      }
    },
    showName(client, agent) {
      if (!client && !agent) return ''
      console.log(client)
      const user = this.$store.getters['user-auth/getUser']
      if (user.role === 'CLIENT') return `${agent.lastname} ${agent.firstname}`
      return client.business === '' ? `${client.lastname} ${client.firstname}` : client.business
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style>
  .board-container {
    overflow-x: auto; /* Enables horizontal scrolling for the container */
    margin-right: 2vh;
    /* white-space: nowrap; */
  }
</style>

<style lang="scss">

@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}

$ease-out: all .3s cubic-bezier(0.23, 1, 0.32, 1);

ul.drag-list, ul.drag-inner-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.drag-container {
    max-width: 100rem;
    margin: 5px auto;
}

.drag-list {
    display: flex;
    align-items: flex-start;

    @media (max-width: 690px) {
        display: block;
    }
}

.drag-column {
    max-width: 400px;
    flex: 1;
    margin: 0 10px;
    padding-bottom: 10px;
    position: relative;
    box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
    border-radius: 4px;

    @media (max-width: 690px) {
        margin-bottom: 30px;
    }

    h2 {
        font-size: 0.8rem;
        margin: 0;
        text-transform: uppercase;
        font-weight: 600;
    }
}

.transparent-input {
    background-color: transparent;
    border: none; /* Remove any border */
    outline: none; /* Remove focus outline */
    color: #000; /* Set text color */
    width: 100%;
    resize: none;
    /* Add any additional styles you want */
}

.drag-inner-list {
    min-height: 50px;
    color: white;
}

.drag-item {
    border-style: solid;
    border-color: rgb(186, 194, 195);
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    // height: 150px;
    border-radius: 10px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(34, 41, 47, 0.05);
    color: black;
    transition: $ease-out;
    box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);

    &.is-moving {
        transform: scale(1);
        background: #fff;
    }

}

.drag-header-more {
    cursor: pointer;
}

.drag-options {
    position: absolute;
    top: 44px;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    transform: translateX(100%);
    opacity: 0;
    transition: $ease-out;

    &.active {
        transform: translateX(0);
        opacity: 1;
    }

    &-label {
        display: block;
        margin: 0 0 5px 0;

        input {
            opacity: 0.6;
        }

        span {
            display: inline-block;
            font-size: 0.9rem;
            font-weight: 400;
            margin-left: 5px;
        }
    }
}

/* Dragula CSS  */

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
    list-style-type: none;
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
</style>
