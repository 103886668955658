<template>
  <!-- modal -->
  <b-modal
    id="add-column-modal"
    ref="add-column-modal"
    title="Task"
    ok-title="Add"
    cancel-variant="outline-secondary"
    @hidden="resetForm"
    @ok="onSubmit"
  >
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- TITLE -->
        <b-row>
          <b-col md="12">
            <validation-provider
              #default="validationContext"
              name="title"
              rules="required"
            >
              <b-form-group
                label="Title"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="taskData.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Doe"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <!-- description -->
            <validation-provider
              #default="validationContext"
              name="description"
              rules="required"
            >
              <b-form-group
                label="Description"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="taskData.description"
                  :state="getValidationState(validationContext)"
                  trim
                  required
                  placeholder="Add description"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <!-- init datepicker -->
            <validation-provider
              #default="validationContext"
              name="initdate"
              rules="required"
            >
              <b-form-group
                label=""
                label-for="initdate"
              >
                <b-form-datepicker
                  id="initdate"
                  v-model="dateInit"
                  local="en"
                  placeholder=""
                  :state="getValidationState(validationContext)"
                  class="mb-1"
                  selected-variant="primary"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="1"
            class="text-center"
          >
            <p>to</p>
          </b-col>
          <b-col md="5">
            <!-- init datepicker -->
            <validation-provider
              #default="validationContext"
              name="enddate"
              rules="required"
            >
              <b-form-group
                label=""
                label-for="enddate"
              >
                <b-form-datepicker
                  id="enddate"
                  v-model="dateEnd"
                  local="en"
                  placeholder=""
                  class="mb-1"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  :state="getValidationState(validationContext)"
                  selected-variant="primary"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <!-- PRIORITY -->
            <validation-provider
              #default="validationContext"
              name="priority"
              rules="required"
            >
              <b-form-group
                label="Priority"
                label-for="priority"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="taskData.priority"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="priorityOptions"
                  :clearable="false"
                  input-id="priority"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </form>
    </validation-observer>

  </b-modal>
</template>

<script>
import {
  BFormGroup, BModal, VBModal, BFormInput, BFormTextarea, BFormInvalidFeedback, BFormDatepicker, BCol, BRow,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, alphaNum, email, min,
} from '@validations'
import { unixTimestampToDate, dateToUnixTimestamp } from '@core/utils/filter'
import store from '@/store'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BFormDatepicker,
    vSelect,
    BCol,
    BRow,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    lanes: {
      type: Array,
      required: true,
    },
    selectedKanban: {
      type: String,
      required: true,
    },
    refreshHandler: {
      type: Function,
      required: true,
    },
    selectedTask: {
      type: Object,
      required: true,
    },
    updateBlock: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      passwordFieldType: 'password',
      email,
      min,
    }
  },
  setup(props) {
    const blankTask = {
      description: '',
      id: '',
      priority: null,
      timelineInit: '',
      timelineEnd: '',
      title: '',
      uid: '',
    }
    const dateInit = ref(null)
    const dateEnd = ref(null)

    const priorityOptions = ref(['High', 'Medium', 'Low'])

    const taskData = ref(JSON.parse(JSON.stringify(blankTask)))

    const resetTaskData = () => {
      taskData.value = JSON.parse(JSON.stringify(blankTask))
      dateInit.value = null
      dateEnd.value = null
    }

    const myPropRef = ref(props.selectedTask)
    const getTask = task => {
      if (task.uid === '') return
      taskData.value.description = task.description
      taskData.value.id = task.id
      taskData.value.priority = task.priority
      taskData.value.status = task.status
      taskData.value.timelineInit = task.timelineInit
      taskData.value.timelineEnd = task.timelineEnd
      taskData.value.title = task.title
      taskData.value.uid = task.uid
      dateInit.value = unixTimestampToDate(task.timelineInit)
      dateEnd.value = unixTimestampToDate(task.timelineEnd)
    }

    // Watch for changes to the prop value
    watch(() => props.selectedTask, newValue => {
      getTask(JSON.parse(JSON.stringify(newValue.value)))
    })

    const updateTask = () => {
      const task = taskData.value
      const ids = {
        idTask: task.uid,
        id: props.selectedKanban,
      }
      props.updateBlock(ids, task)
    }

    const onSubmit = async () => {
      taskData.value.idKanban = props.selectedKanban
      taskData.value.timelineInit = dateToUnixTimestamp(dateInit.value)
      taskData.value.timelineEnd = dateToUnixTimestamp(dateEnd.value)
      if (taskData.value.uid !== '') {
        updateTask()
      } else {
        store.dispatch('app-kanban/addTaskKanban', taskData.value)
          .then(() => {
            props.refreshHandler(props.selectedKanban)
          })
      }
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetTaskData)

    return {
      resetTaskData,
      taskData,
      onSubmit,
      getTask,
      priorityOptions,
      myPropRef,
      updateTask,
      dateInit,
      dateEnd,

      refFormObserver,
      getValidationState,
      resetForm,

      unixTimestampToDate,
      dateToUnixTimestamp,
    }
  },
}
</script>
